const LegacyArrowRightIcon = ({ ...others }) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path d="M26.707 17.293l6 6c.029.028.055.059.08.09l-.08-.09a1.008 1.008 0 01.292.675L33 24v.033l-.004.052L33 24a1.008 1.008 0 01-.213.617l-.007.008-.073.082-6 6a1 1 0 01-1.414-1.414L29.585 25H16a1 1 0 010-2h13.585l-4.292-4.293a1 1 0 011.414-1.414z" />
  </svg>
)

export { LegacyArrowRightIcon }
