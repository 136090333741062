import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { Grid as CreditasGrid } from 'components/Grid'

export const Grid = styled(CreditasGrid)`
  grid-template-areas:
    '${'infos '.repeat(4)}'
    '${'stepper '.repeat(4)}'
    '${'award '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'infos '.repeat(8)}'
      '${'stepper '.repeat(8)}'
      '${'award '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-template-areas:
      '${'infos '.repeat(7)} ${'stepper '.repeat(4)} . '
      '${'award '.repeat(6)} . ${'stepper '.repeat(4)} . ';
  }

  ${media.up('6xl')} {
    grid-template-areas:
      '${'infos '.repeat(6)} . ${'stepper '.repeat(4)} . '
      '${'award '.repeat(6)} . ${'stepper '.repeat(4)} . ';
  }
`
