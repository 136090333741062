import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import RichText from 'components/RichText'

export const PreTitle = styled(RichText)`
  margin-bottom: 16px;
  text-transform: uppercase;
`

export const Title = styled(RichText)`
  margin-bottom: 24px;
`

export const Description = styled(RichText)`
  ${media.up('5xl')} {
    margin-bottom: 0;
    max-width: 495px;
  }
`

export const InfosWrapper = styled.div`
  grid-area: infos;
`

export const AwardWrapper = styled.div`
  grid-area: award;
  margin-top: 36px;

  p {
    color: ${selectTheme('colors.neutral.60')};
  }

  ${media.up('5xl')} {
    margin-top: 64px;
  }

  ${media.up('4xl')} {
    p {
      max-width: 360px;
    }
  }
`

export const StepperWrapper = styled.div`
  grid-area: stepper;
  margin-top: 40px;

  ${media.up('5xl')} {
    margin-top: 0;
  }
`
