import { oneOf } from 'prop-types'

import Template01 from './Template01'
import Template02 from './Template02'

const propTypes = {
  variant: oneOf(['Template01', 'Template02']),
}

const TEMPLATES = {
  Template01,
  Template02,
}

const defaultProps = {
  variant: 'Template01',
}

const HeroStepper = ({ variant, ...others }) => {
  const Section = TEMPLATES[variant || defaultProps.variant]

  return <Section variant={variant} {...others} />
}

HeroStepper.propTypes = propTypes
HeroStepper.defaultProps = defaultProps

export default HeroStepper
