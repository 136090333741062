import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container } from 'components/Container'

import { Grid as BaseGrid } from '../../base.styles'

export const Section = styled.section`
  height: calc(3vh + 632px);
  background-repeat: no-repeat;
  background-color: none;
  background-image: linear-gradient(
    180deg,
    ${selectTheme('colors.primary.60')} 48%,
    transparent 48%
  );

  ${media.up('5xl')} {
    height: 420px;
    background-color: ${selectTheme('colors.primary.60')};
  }
`

export const Grid = styled(BaseGrid)`
  grid-template-rows: max-content;
`

export { Container }
