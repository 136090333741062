import { node } from 'prop-types'

import { Container, Grid, Section } from './styles'

const propTypes = {
  children: node,
}

const Wrapper = ({ children, ...others }) => (
  <Section {...others} data-testid="heroStepper-template01">
    <Container>
      <Grid>{children}</Grid>
    </Container>
  </Section>
)

Wrapper.propTypes = propTypes

export default Wrapper
