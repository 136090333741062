import React from 'react'
import { func, oneOf, string } from 'prop-types'

import { ButtonPrimaryWithArrow } from '@creditas-ui/button'

import { LegacyArrowRightIcon } from 'components/Icons/LegacyArrowRightIcon'

import { Legacy, StyledButtonCta } from './styles'

const propTypes = {
  children: string,

  onClick: func,
  variant: oneOf(['legacy', 'newer']),
}

const ButtonCta = ({ children, onClick, variant }) => {
  const buttonTypes = {
    legacy: {
      Component: Legacy,
      props: {
        endIcon: <LegacyArrowRightIcon />,
        variant: 'primary',
        children,
      },
    },
    newer: {
      Component: StyledButtonCta,
      props: {
        element: ButtonPrimaryWithArrow,
        width: 'flexible',
        size: 'large',
        isButtonElement: true,
        children,
      },
    },
  }

  const { Component, props } = buttonTypes[variant]

  return (
    <Component
      {...props}
      actionType="customAction"
      onClick={onClick}
      analyticEvent={false}
    />
  )
}

ButtonCta.propTypes = propTypes

export default ButtonCta
