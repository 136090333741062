import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import RichText from 'components/RichText'

export const PreTitle = styled(RichText)``

export const Title = styled(RichText)`
  margin: 10px 0;
  margin-right: auto;

  ${media.up('5xl')} {
    margin-right: 6%;
  }
`

export const Description = styled(RichText)`
  margin-bottom: 0;

  ${media.up('5xl')} {
    margin-top: 30px;
  }
`

export const InfosWrapper = styled.div`
  grid-area: infos;
  margin-top: 24px;

  ${media.up('5xl')} {
    margin-top: 48px;
  }
`

export const AwardWrapper = styled.div`
  grid-area: award;
  margin-top: 24px;

  p {
    color: ${selectTheme('legacyColors.neutral09')};

    ${media.up('5xl')} {
      color: ${selectTheme('colors.neutral.0')};
    }
  }
`

export const StepperWrapper = styled.div`
  grid-area: stepper;
  margin-top: 48px;
`
