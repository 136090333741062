import { styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

import { ButtonCta } from 'components/ButtonCta'
import { Cta } from 'components/Cta'

export const Legacy = styled(ButtonCta)`
  &[class^='css-'] {
    margin-top: 66px;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 22px;
    }
  }
`

export const StyledButtonCta = styled(Cta)`
  margin-top: 80px;
  text-decoration: none;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: ${selectTheme('shadow.xs')};
  white-space: nowrap;
  width: 100%;
`
