import { styled } from '@creditas-ui/system'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

const Card = styled.div`
  background-color: ${selectTheme('colors.neutral.0')};
  border: 1px solid ${selectTheme('legacyColors.neutral03')};
`

export const LegacyWrapper = styled(Card)`
  border-radius: 4px;
  padding: 24px;
  min-height: 300px;
`

export const NewerWrapper = styled(Card)`
  padding: 40px 32px;
  box-shadow: ${selectTheme('shadow.lg')};
  border-radius: 16px;

  ${media.up('5xl')} {
    padding: 48px 40px;
  }

  [data-testid='stepper-max'],
  [data-testid='stepper-min'] {
    margin-top: 16px;
  }

  button {
    svg {
      fill: ${selectTheme('colors.neutral.90')};

      &:hover {
        opacity: 0.5;
      }
    }
  }

  label {
    ${getTypography('exceptionMdBold')}
    color: ${selectTheme('colors.neutral.90')};
    text-transform: uppercase;

    ${media.up('5xl')} {
      margin-bottom: 16px;
    }
  }

  input {
    color: ${selectTheme('colors.neutral.90')};
    border-color: ${selectTheme('colors.neutral.40')};
  }
`
