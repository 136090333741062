import { node } from 'prop-types'

import { Grid, Section } from './styles'

const propTypes = {
  children: node,
}
const Wrapper = ({ children, ...others }) => (
  <Section as="section" data-testid="heroStepper-template02" {...others}>
    <Grid>{children}</Grid>
  </Section>
)

Wrapper.propTypes = propTypes

export default Wrapper
