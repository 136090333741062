import { bool, object, oneOf, string } from 'prop-types'

import ReclameAquiAward from 'components/ReclameAquiAward'

import ProductFormStepper from '../components/ProductFormStepper'

import Wrapper from './Wrapper'

import {
  AwardWrapper,
  Description,
  InfosWrapper,
  PreTitle,
  StepperWrapper,
  Title,
} from './styles'

const propTypes = {
  description: string,
  preTitle: string,
  preTitleHeadingTag: oneOf(['h1', 'h2']),
  productFormStepper: object,
  showReclameAquiAward: bool,
  subtitle: string,
  title: string,
  titleHeadingTag: oneOf(['h1', 'h2']),
  titleText: string,
  variant: oneOf(['Template01', 'Template02']),
}

const defaultProps = {
  preTitleHeadingTag: 'h1',
  variant: 'Template01',
}

const Template01 = ({
  description,
  preTitle,
  preTitleHeadingTag,
  productFormStepper,
  showReclameAquiAward,
  subtitle,
  title,
  titleHeadingTag,
  titleText,
  variant,
  ...others
}) => {
  const titleAs = preTitle ? titleHeadingTag || 'h2' : 'h1'
  const color = 'neutral.0'
  const showReclameAqui =
    showReclameAquiAward === null
      ? defaultProps.showReclameAquiAward
      : showReclameAquiAward

  return (
    <Wrapper {...others}>
      <InfosWrapper>
        <PreTitle
          as={preTitleHeadingTag || defaultProps.preTitleHeadingTag}
          color={color}
          variant={{ _: 'bodyLgMedium', '5xl': 'headingLgMedium' }}
        >
          {preTitle}
        </PreTitle>

        <Title
          as={titleAs}
          color={color}
          variant={{ _: 'headingLgMedium', '5xl': 'heading3xlMedium' }}
        >
          {titleText || title}
        </Title>

        <Description
          color={color}
          data-testid="description"
          variant="bodyMdRegular"
        >
          {description || subtitle}
        </Description>
      </InfosWrapper>

      {showReclameAqui && (
        <AwardWrapper>
          <ReclameAquiAward variant="white" />
        </AwardWrapper>
      )}

      <StepperWrapper>
        <ProductFormStepper variant="legacy" {...productFormStepper} />
      </StepperWrapper>
    </Wrapper>
  )
}

Template01.propTypes = propTypes
Template01.defaultProps = defaultProps

export default Template01
