import { useState } from 'react'
import { bool, number, object, oneOf, shape, string } from 'prop-types'

import { Stepper } from '@creditas/form-elements/dist/Stepper'
import { useSimulator } from '@creditas-lab/simulator'
import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { leadFormURL } from 'utils/leadFormURL'

import ButtonCta from './ButtonCta'

import { LegacyWrapper, NewerWrapper } from './styles'

const propTypes = {
  callToActionText: string,
  incrementValue: string,
  label: string,
  labelMaxValue: string,
  labelMinValue: string,
  messageMaxValue: string,
  messageMinValue: string,
  prefix: string,
  product: shape({
    icon: object,
    leadForm: string,
    maxLoanFullValue: string,
    maxLoanValue: string,
    maximumYearCar: string,
    minLoanFullValue: string,
    minLoanInterest: string,
    minLoanInterestYear: string,
    minLoanValue: string,
    termMax: number,
    termMin: number,
    title: string,
  }),
  useInternalSimulator: bool,
  variant: oneOf(['legacy', 'newer']),
}

const defaultProps = {
  variant: 'newer',
  useInternalSimulator: false,
}

const Variants = {
  legacy: {
    variantStatus: 'success',
    Wrapper: LegacyWrapper,
  },
  newer: {
    variantStatus: 'default',
    Wrapper: NewerWrapper,
  },
}

const ProductFormStepper = ({
  callToActionText,
  incrementValue,
  label,
  labelMaxValue,
  labelMinValue,
  messageMaxValue,
  messageMinValue,
  prefix,
  product,
  useInternalSimulator,
  variant,
}) => {
  const { leadForm, maxLoanFullValue = 0, minLoanFullValue = 0, productType } =
    product || {}

  const { triggerEvent } = useTriggerEvents()

  const [loanValue, setLoanValue] = useState(Number(minLoanFullValue))
  const [isValidValue, setIsValidValue] = useState(true)
  const [error, setError] = useState(true)
  const { openSimulator } = useSimulator()

  const minValue = Number(minLoanFullValue)
  const maxValue = Number(maxLoanFullValue)

  const handleInput = ({ target: { value } }) => setLoanValue(value)

  const handleFocus = () => {
    triggerEvent({
      label: 'simulator | input stepper',
      pageInteraction: true,
    })
  }

  const triggerClickEvents = () => {
    // This events aren't duplicated. It was a product decision.
    triggerEvent({
      label: `simulator | ${callToActionText}`,
      pageInteraction: !!useInternalSimulator,
    })

    triggerEvent({
      label: `simulator | ${loanValue}`,
      pageInteraction: !!useInternalSimulator,
    })
  }

  const handleClick = () => {
    if (!isValidValue) return

    triggerClickEvents()

    if (useInternalSimulator && productType) {
      openSimulator(loanValue, productType)
    } else {
      window.location.href = leadFormURL(
        `${leadForm}?valor-emprestimo=${loanValue}`,
      )
    }
  }

  const handleDecrement = () => {
    triggerEvent({
      label: 'simulator | minus value',
      pageInteraction: true,
    })
  }

  const handleIncrement = () => {
    triggerEvent({
      label: 'simulator | plus value',
      pageInteraction: true,
    })
  }

  const handleValid = () => {
    setIsValidValue(true)
    setError('')
  }

  const handleBlur = () => {
    if (isValidValue) return

    triggerEvent({
      label: `simulator | error | ${error}`,
      pageInteraction: true,
    })
  }

  const handleInvalid = ({ max, min }) => {
    const message =
      (min && 'value below minimum') || (max && 'value above maximum')

    setIsValidValue(false)
    setError(message)
  }

  const { variantStatus, Wrapper } = Variants[variant || defaultProps.variant]

  return (
    <Wrapper>
      <Stepper
        increment={Number(incrementValue || 1)}
        label={label}
        min={{
          amount: minValue,
          label: labelMinValue,
        }}
        max={{
          amount: maxValue,
          label: labelMaxValue,
        }}
        messages={{
          min: `${messageMinValue} %min%`,
          max: `${messageMaxValue} %max%`,
        }}
        onInput={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onDecrement={handleDecrement}
        onIncrement={handleIncrement}
        onValid={handleValid}
        onInvalid={handleInvalid}
        prefix={prefix}
        value={loanValue}
        variant={variantStatus}
      />

      <ButtonCta variant={variant} onClick={handleClick} as="button">
        {callToActionText}
      </ButtonCta>
    </Wrapper>
  )
}

ProductFormStepper.propTypes = propTypes
ProductFormStepper.defaultProps = defaultProps

export default ProductFormStepper
