import { bool, object, oneOf, string } from 'prop-types'

import ReclameAquiAward from 'components/ReclameAquiAward'

import ProductFormStepper from '../components/ProductFormStepper'

import Wrapper from './Wrapper'

import {
  AwardWrapper,
  Description,
  InfosWrapper,
  PreTitle,
  StepperWrapper,
  Title,
} from './styles'

const propTypes = {
  description: string,
  preTitle: string,
  preTitleHeadingTag: oneOf(['h1', 'h2']),
  productFormStepper: object,
  showReclameAquiAward: bool,
  subtitle: string,
  title: string,
  titleHeadingTag: oneOf(['h1', 'h2']),
  titleText: string,
  variant: oneOf(['Template02', 'Template02']),
}

const defaultProps = {
  preTitleHeadingTag: 'h1',
  titleHeadingTag: 'h2',
  variant: 'Template02',
  showReclameAquiAward: true,
}

const Template02 = ({
  description,
  preTitle,
  preTitleHeadingTag,
  productFormStepper,
  showReclameAquiAward,
  subtitle,
  title,
  titleHeadingTag,
  titleText,
  variant,
  ...others
}) => {
  const showReclameAqui =
    showReclameAquiAward === null
      ? defaultProps.showReclameAquiAward
      : showReclameAquiAward

  return (
    <Wrapper {...others}>
      <InfosWrapper>
        <PreTitle
          as={preTitleHeadingTag || defaultProps.preTitleHeadingTag}
          color="neutral.60"
          variant={{ _: 'exceptionSmBold', '5xl': 'exceptionMdBold' }}
        >
          {preTitle}
        </PreTitle>

        <Title
          as={titleHeadingTag || defaultProps.titleHeadingTag}
          color="neutral.90"
          variant={{ _: 'headingMdLight', '5xl': 'heading2xlLight' }}
        >
          {titleText || title}
        </Title>

        <Description
          color="neutral.60"
          data-testid="description"
          variant={{ _: 'bodyMdRegular', '5xl': 'bodyXlRegular' }}
        >
          {description || subtitle}
        </Description>
      </InfosWrapper>

      {showReclameAqui && (
        <AwardWrapper>
          <ReclameAquiAward variant="black" />
        </AwardWrapper>
      )}

      <StepperWrapper>
        <ProductFormStepper variant="newer" {...productFormStepper} />
      </StepperWrapper>
    </Wrapper>
  )
}

Template02.propTypes = propTypes
Template02.defaultProps = defaultProps

export default Template02
