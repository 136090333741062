import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container } from 'components/Container'

import { Grid as BaseGrid } from '../../base.styles'

export const Section = styled(Container)`
  padding-top: ${selectTheme('spacingTop.8xl')};
  padding-bottom: ${selectTheme('spacingBottom.8xl')};

  ${media.up('5xl')} {
    padding-top: ${selectTheme('spacingTop.10xl')};
    padding-bottom: ${selectTheme('spacingBottom.21xl')};
  }
`

export const Grid = styled(BaseGrid)`
  ${media.up('5xl')} {
    grid-template-areas:
      '. ${'infos '.repeat(6)} ${'stepper '.repeat(4)} . '
      '. ${'award '.repeat(6)} ${'stepper '.repeat(4)} . ';
  }
`
